.news-container {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .readable-link {
    font-size: 16px;
    color: #007bff; /* Set link color */
    text-decoration: underline; /* Add underline for readability */
    cursor: pointer; /* Add pointer cursor for indicating it's clickable */
  }
  